#root {
  position: relative;
  min-height: 100vh;
}

body {
  font-family: 'Sora', sans-serif;
  font-size: 1.0em;
  line-height: 1.5em;
  color: #fff;
}

h1, h2 {
  font-size: 1.6em;
  font-family: 'Allerta Stencil', sans-serif;
}

h2 {
  font-size: 1.2em;
  font-family: 'Allerta Stencil', sans-serif;
}


html {
  background: #0078BA;
}

body, html {
  height: 100%;
  padding: 0;
  margin: 0;
}

ul, li {
  list-style-type: none;
  list-style-position: inside;
  padding: 0;
  border: 0;
  text-indent: 0;
}

a {
  text-decoration: none;
  color: #fff;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
