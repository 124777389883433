.hero-container {
  margin-left: 620px;
}

.main-heading {
  font-size: 1.9em;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.terms-container {
  width: 60%;
  height: auto;
  text-align: left;
}

.terms-section {
  width: 100%;
  height: 100%;
}

.modal {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: '50%';
  transform: 'translate(50%, 50%)';
  z-index: 1999;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.modal-content {
  max-width: 70%;
  max-height: 80%;
  width: auto;
  height: auto;
  top: '75%';
  left: '50%';
  transform: 'translate(50%, 75%)';
  background-color: #2D5F74;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  position: relative;
}

.lesson-plan-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.row-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-section {
  height: 1680px;
  max-height: 85%;
}

.unityContainer {
  max-width: 100%;
  max-height: 100%;
}

.footer-row-section {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 130px;
}

.fc31jf {
  padding-right: 15px;
}

.footer-col-section {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.q35dsa {
  font-size: 1.6em;
}

.col-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fixed-grid {
  align-items: flex-start;
}

.grid-box {
  width: auto;
  height: auto;
  max-width: 100%;
}

.simulation-desc {
  background-color: #2D5F74;
  width: 100vw;
  height: 325px;
}

@media only screen and (min-width: 761px) {
  .simulation-desc {
    width: 100vw;
    height: 325px;
  }
}

.unityContainer {
  width: 100%;
  height: 100%;
}


.ge452l {
  gap: 25px;
}

.afy2q1 {
  gap: 50px;
}

.jkdhf3 {
  gap: 75px;
}

.je452l {
  gap: 100px;
}

.box-desc {
  text-align: center;
  max-width: 425px;
}

@media only screen and (min-width: 761px) {
  .box-desc {
    text-align: left;
    max-width: 400px;
  }
}

.left-align {
  text-align: left;
}

.sub-hh {
  border: 3px solid #fff;
  padding: 10px;
}

.ht-sim-desc {
  max-width: 900px;
}

.tc-784k {
  text-align: center;
}

.sb-h {
  margin-bottom: 10px;
}

.e-t3p {
  color: #000;
}

.boer43 {
  padding-left: 10px;
}

.instruction-button {
  cursor: pointer;
}

.aerfas {
  padding-bottom: 30px;
}

.mt-983k {
  padding-bottom: 50px;
}

.me-5grk {
  padding-bottom: 125px;
}

.ma-5grk {
  padding-bottom: 200px;
}

.ma-e3qk {
  padding-bottom: 250px;
}

.eyt32p {
  margin-bottom: -20px;
}

.ow0zr9 {
  text-align: right;
}

.team-section {
  max-height: 100%;
  height: 1960px;
}

.loading {
  text-align: center;
}

.oearfz {
  text-decoration: underline;
}

@media only screen and (min-width: 761px) {
  .team-section {
    max-height: 100%;
    height: 1040px;
  }
}

.lab-section {
  padding: 50px 0;
  max-height: 100%;
  height: auto;
}

.arrow {
  border: 3px solid #000;
  border-width: 0px 3px 3px 0px;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.copyright {
  font-size: 0.9em;
}

.exit-button {
  cursor: pointer;
}

.teacher-section {
  width: auto;
  height: fit-content;
}

.a-3ugir {
  border-bottom: 1px solid #fff;
}

.l-3ugir {
  border-left: 1px solid #fff;
  height: 160px;
}

.t-3ugir {
  border-top: 1px solid #fff;
  width: 225px;
}

.aewr31 {
  padding-top: 25px;
}

.a43jfr {
  padding-top: 50px;
}

.m31-5p {
  padding-top: 75px;
}

.a3jpy1 {
  padding-top: 100px;
}

.a3jpy3 {
  padding-top: 125px;
}

.a3jpy6 {
  padding-top: 150px;
}

.u-4ugir {
  border-bottom: 2px solid #fff;
}

.unity-section {
  width: auto;
  height: auto;
}

.desc {
  margin-bottom: 20px;
}

.column-image {
  width: 175px;
  height: 150px;
}

.unity-image {
  width: 200px;
  height: 150px;
}

@media only screen and (min-width: 761px) {
  .column-image {
    width: 275px;
    height: 225px;
  }
  .unity-image {
    width: 300px;
    height: 225px;
  }
}

.section {
  max-height: 85%;
  height: 700px;
}

.home-section {
  max-height: 85%;
  height: 700px;
}

.behind-about-section {
  max-height: 85%;
  height: 100vh;
}

.half-section {
  max-height: 85%;
  height: 360px;
}

.quarter-section {
  max-height: 85%;
  height: 160px;
}


.dropdown {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background: #0078BA;
  z-index: 2021;
  border-bottom: 1px solid #34558b;
}

.dropdown-cursor {
  cursor: pointer;
}

@media only screen and (min-width: 761px) {

  .show {
    display: none;
  }
}

.quarter-section-padding {
  padding: 30px 0;
}

.bor-bot {
  border-bottom: 1px solid #fff;
}

.about-header-section {
  max-height: 85%;
  height: 240px;
}

@media only screen and (min-width: 761px) {
  .about-header-section {
    max-height: 85%;
    height: 160px;
  }
} 

.about-bts-section {
  max-height: 85%;
  height: 1200px;
}


.link-button {
  background-color: #34558b;
  border: 2px solid #ffffff;
  border-radius: 25px;
  padding: 10px;
  transition: background-color 250ms ease-in-out, 
                transform 200ms ease;
}

.link-button:hover,
.link-button:focus {
  padding: 10px;
  background-color: #42ADD5;
}

.link-button:active {
  transform: scale(0.99);
}

.Header {
  background: #0078BA;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 25px 0;
  border-bottom: 1px solid #34558b;
}

.Footer {
  background: #0078BA;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 30px 0;
  gap: 20px;
  border-top: 1px solid #34558b;
}

.Footer-row {
  display: relative;
  width: 100%;
}

.grid-two-by-two {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
}

.grid-two-box {
  display: flex; 
  flex-direction: column;
  margin: 0 30px;
}

@media only screen and (min-width: 761px) {
  .Home-Header {
    background: transparent;
    position: absolute;
    top: 0;
    border-bottom: none;
  }
}

.App {
  text-align: center;
}

.logo-image {
  display: block;
  max-width: 250px;
  max-height: 175px;
  width: 100%;
  height: 100%;
}

.page-container {
  cursor: pointer;
  padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-fade-slide ease 3s;
    -webkit-animation: App-logo-fade-slide ease 3s;
    -moz-animation: App-logo-fade-slide ease 3s;
    -o-animation: App-logo-fade-slide ease 3s;
    -ms-animation: App-logo-fade-slide ease 3s;
  }
}

.App-link {
  color: #61dafb;
}

.footer-nav-item {
  text-align: center;
}

.nav-item {
  padding-left: 30px;
  padding-right: 30px;
}

.nav-link:hover,
.nav-link:focus {
  color: #42ADD5;
}

.nav-link:active {
  transform: scale(0.99);
}

.lab-link {
  font-weight: bold;
  text-decoration: underline;
  transition: color 250ms ease-in-out, 
                transform 200ms ease;
}

.lab-link:hover,
.link-button:focus {
  color: #42ADD5;
}

.social-nav-list {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.social-nav-item {
  padding-left: 5px;
  padding-right: 5px;
}

.nav-link {
  transition: color 250ms ease-in-out, 
                transform 200ms ease;
  color: #fff;
  font-size: 0.9em;
}

.nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-nav-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footer-social-media {
}

@keyframes App-logo-fade-slide {
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
